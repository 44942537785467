<msep-alert
  title="Your password must meet the following security standards"
  [type]="alertType"
>
  <div class="password-requirements">
    <span [class]="meetsLengthRequirement ? 'green' : 'red'">
      <ng-container
        *ngIf="
          meetsLengthRequirement;
          then requirementMetIcon;
          else requirementNotMetIcon
        "
      >
      </ng-container>
      A minimum of 15 characters
    </span>
    <br />
    <span [class]="hasCapitalLetter ? 'green' : 'red'">
      <ng-container
        *ngIf="
          hasCapitalLetter;
          then requirementMetIcon;
          else requirementNotMetIcon
        "
      >
      </ng-container>
      Include at least one uppercase alphabetic character
    </span>
    <br />
    <span [class]="hasLowerCaseLetter ? 'green' : 'red'">
      <ng-container
        *ngIf="
          hasLowerCaseLetter;
          then requirementMetIcon;
          else requirementNotMetIcon
        "
      >
      </ng-container>
      Include at least one lowercase alphabetic character
    </span>
    <br />
    <span [class]="hasSpecialCharacter ? 'green' : 'red'">
      <ng-container
        *ngIf="
          hasSpecialCharacter;
          then requirementMetIcon;
          else requirementNotMetIcon
        "
      >
      </ng-container>
      Include at least one non-alphanumeric (special) character
    </span>
    <br />
    <span [class]="hasNumber ? 'green' : 'red'">
      <ng-container
        *ngIf="hasNumber; then requirementMetIcon; else requirementNotMetIcon"
      >
      </ng-container>
      Include at least one number
    </span>
  </div>
  <div>
    <i> * Cannot be one of your last five passwords used</i>
  </div>

  <ng-template #requirementMetIcon>
    <mat-icon class="green" fontIcon="check_circle"></mat-icon>
  </ng-template>
  <ng-template #requirementNotMetIcon>
    <mat-icon class="red" fontIcon="cancel"></mat-icon>
  </ng-template>
</msep-alert>
