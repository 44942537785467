<button
  class="flex flex-fill"
  mat-raised-button
  (click)="onExportClick()"
  type="button"
  [class.in-group]="inButtonGroup"
  [disabled]="disabled || isLoading"
  [class.spinner]="isLoading"
>
  <mat-icon
    aria-hidden="false"
    aria-label="arrow facing down above line"
    fontIcon="file_download"
  >
  </mat-icon>
  Export
</button>
