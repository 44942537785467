<mat-form-field appearance="outline">
  <mat-label>Location</mat-label>
  <mat-icon matIconPrefix *ngIf="!isLoading" fontIcon="place"></mat-icon>
  <mat-spinner
    matIconPrefix
    aria-label="Loading location information"
    [diameter]="24"
    class="mat-prefix-spinner"
    *ngIf="isLoading"
  ></mat-spinner>
  <input
    matInput
    id="autocomplete"
    #autocompleteInput
    placeholder="Enter a place"
    type="text"
    [required]="required"
    [value]="value"
    [disabled]="disabled"
    (blur)="checkClearedInput()"
  />
  <mat-hint *ngIf="!disabled">
    Example: Full Address, City, State, ZIP Code
  </mat-hint>
</mat-form-field>
