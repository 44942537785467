export enum AccountStatus {
  Active = 1,
  Pending,
  Disapproved,
  Locked,
  Suspended,
}

export enum ApplicationStatus {
  Pending = 1,
  InReview,
  Approved,
  Disapproved,
  OnHold,
}

export enum AssistanceType {
  HowToJoinMSEP = 1,
  GeneralProgramQuestions,
  SharingAnInstallationEvent,
  OtherNotRegistered,
  TechnicalAssistance,
  JobPostingOrFeeds,
  PartnerReporting,
  OtherRegistered,
  BecomeMSEPPartner,
  NewUserAccount,
}

export enum Benefits {
  Offers401k = 1,
  TuitionAssistance,
  FinancialAssistance,
  StockOptions,
  LifeInsurance,
  HealthInsurance,
  DentalInsurance,
  Childcare,
  JobPortability,
}

export enum ContactUsTicketStatus {
  InProgress = 2,
  OnHold = 4,
  Pending = 1,
  Resolved = 3,
}

export enum Countries {
  UnitedStates = 1,
}

export enum Docks {
  EngagementTracker = 1,
  Helpdesk,
  Jobs,
  PartnerActivity,
  PartnerEngagement,
  PartnerMetrics,
  RecentlyAccessedPartner,
  SpouseActivity,
  SpouseEngagement,
  UserMetrics,
  PartnerBadge,
}

export enum GhostLoaderType {
  Card = 'card',
  Page = 'page',
  PartnerLogo = 'logo',
  Table = 'table',
}

export enum Icon {
  LinkedIn = 'linkedin',
  Facebook = 'facebook',
  Twitter = 'twitter',
  Pinterest = 'pinterest',
  Instagram = 'instagram',
}

export enum JobFeedSourceUrlType {
  FeedUrl = 1,
  S3,
  USAJOBS,
}

export enum JobFeedType {
  XML = 1,
  CSV,
  JSON,
}

export enum MsepChartType {
  Line = 1,
  Pie,
  SpouseEngagementMultiLine,
  TotalPublishedJobsMultiLine,
  JobMap,
  SpouseMap,
}

export enum LineChart {
  CandidateSearch = 1,
  SpousesHired,
  SpousesCurrentlyEmployed,
  SpouseEngagement,
  PartnersWhoReported,
  TotalPartners,
  TotalJobs,
}

export enum PieChart {
  Industry = 1,
  YearsOfExperience,
  EducationLevel,
  RemoteTeleworkJob,
  PartnerIndustry,
  RemoteTeleworkPartner,
  PartnershipType,
}

export enum PartnershipTypeId {
  EmployerPartners = 1,
  SpouseAmbassadorNetwork,
  FederalAgencyPartners,
  MsepEducation,
  MsepSmallBusiness,
}

export enum PartnerReports {
  PartnerJobs = 1,
  PartnerEngagement,
  HiringData,
  TeleworkRemote,
  SpecialistSpouseEngagement,
  SpecialistSpousesHired,
  SpouseHire,
  SpouseEmploymentDetails,
  SpecialistHotJobs,
  CandidateStatus,
  PartnerCandidateView,
}

export enum PartnerResourceType {
  UserManual = 39,
}

export enum SpouseEngagement {
  SpouseClickedApply = 1,
  SpouseSearchedProfile,
  SpouseSavedProfile,
  SpouseViewedProfile,
}

export enum Permission {
  ManageReports = 'Manage Reports',
  ManageUsers = 'Manage Users',
  Helpdesk = 'Helpdesk',
  ManageJobs = 'Manage Jobs',
  ManagePartnerProfiles = 'Manage Partner Profiles',
  PartnerAdmin = 'PartnerAdmin',
}

export enum PermissionId {
  ManageReports = 1,
  ManageUsers,
  Helpdesk = 4,
  ManageJobs,
  ManagePartnerProfiles,
  PartnerAdmin,
}

export enum Role {
  SysOp = 'SysOp',
  Govt = 'Govt',
  Specialist = 'Specialist',
  User = 'User',
}

export enum RoleId {
  SysOp = 1,
  Govt,
  Specialist,
  User,
}
