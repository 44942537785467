<ng-container *ngIf="mapChartMetrics$ | async">
  <div class="flex flex-col">
    <msep-export-map-chart-button
      class="export-button"
      [organizationId]="partnerId"
    ></msep-export-map-chart-button>
    <p *ngIf="mapChartMetrics.length < 1" class="flex justify-center chart-message">No job data to display</p>
    <div id="theChart" class="flex justify-center"></div>
  </div>
</ng-container>
