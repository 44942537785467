<div class="exit-modal">
  <div class="exit-warning">
    <div>
      <mat-icon
        class="material-icons mat-exit-modal-icon"
        aria-hidden="false"
        aria-label="Example home icon"
        style="width: auto"
        fontIcon="warning"
      >
      </mat-icon>
    </div>
  </div>

  <div class="flex flex-row flex-wrap justify-center">
    <h1 mat-dialog-title class="flex-100 center">{{ title }}</h1>

    <mat-dialog-content>
      {{ message }}
    </mat-dialog-content>
  </div>

  <div class="flex flex-row justify-center extra-top-margin-32">
    <mat-dialog-actions>
      <button mat-raised-button *ngIf="buttonNo" (click)="onNoClick()">
        {{ buttonNo }}
      </button>
      <button
        *ngIf="buttonYes"
        mat-raised-button
        color="primary"
        cdkFocusInitial
        [mat-dialog-close]="data.confirm"
        (click)="onYesClick()"
      >
        {{ buttonYes }}
      </button>
    </mat-dialog-actions>
  </div>
</div>
